import { Message } from "element-ui";
import Vue from "vue";
import VueRouter from "vue-router";
// import Home from '../views/Home.vue'
import ReportList from "../components/ReportList.vue";

Vue.use(VueRouter);
// 解决路由自己跳自己的报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
const routes = [
  {
    path: "/",
    // 重定向
    redirect: "/login",
  },
  // 注册账号
  {
    path: `/Register`,
    name: "Register",
    component: () => import("../views/login/Register.vue"),
  },
  // 光照报告
  {
    path: `/OneReport/:cid/:id`,
    name: "OneReport",
    component: () => import("../views/report/OneReport.vue"),
  },
  // 患者问卷答题登录验证
  {
    path: "/AssessmentLogin",
    name: "AssessmentLogin",
    component: () => import("../views/assessment/QuestionnaireLogin.vue"),
  },
  {
    path: "/Assessment",
    name: "Assessment",
    component: () => import("../views/assessment/Assessment.vue"),
  },
  // 睡眠评估路由
  {
    path: "/MEQ",
    name: "MEQ",
    component: () => import("../components/assessment/MEQ.vue"),
  },
  // 睡眠质量评估
  {
    path: "/PSQI",
    name: "PSQI",
    component: () => import("../components/assessment/PSQI.vue"),
  },
  // 抑郁评估路由
  {
    path: "/HAMD",
    name: "HAMD",
    component: () => import("../components/assessment/HAMD.vue"),
  },
  // 抑郁症筛查评估
  {
    path: "/PHQ",
    name: "PHQ",
    component: () => import("../components/assessment/PHQ.vue"),
  },
  // 产后抑郁评估
  {
    path: "/EPDS",
    name: "EPDS",
    component: () => import("../components/assessment/EPDS.vue"),
  },
  // 老年抑郁评估
  {
    path: "/GDS",
    name: "GDS",
    component: () => import("../components/assessment/GDS.vue"),
  },
  // 焦虑评估
  {
    path: "/BAI",
    name: "BAI",
    component: () => import("../components/assessment/BAI.vue"),
  },
  // 嗜睡量表评测
  {
    path: "/ESS",
    name: "ESS",
    component: () => import("../components/assessment/ESS.vue"),
  }, // 生会质量评测
  {
    path: "/QOL",
    name: "QOL",
    component: () => import("../components/assessment/QOL-AD.vue"),
  },
  // 脑震荡后综合征问卷
  {
    path: "/RPQ",
    name: "RPQ",
    component: () => import("../components/assessment/RPQ.vue"),
  },
  // 脑震荡后综合征问卷
  {
    path: "/CMAI",
    name: "CMAI",
    component: () => import("../components/assessment/CMAI.vue"),
  },
  // 登录
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/login/Login.vue"),
  },
  // 修改密码
  {
    path: "/Update",
    name: "Update",
    component: () => import("../views/login/Update.vue"),
  },
  {
    path: "/Echart",
    name: "Echart",
    component: () => import("../components/Echarts.vue"),
  },
  // 系统主页
  {
    path: "/system",
    name: "System",
    component: () => import("../views/System.vue"),
    // 路由前置守卫
    beforeEnter: (to, from, next) => {
      const token = localStorage.token;
      if (token) {
        next();
      } else {
        Message.error("你还没有登录，请登录后查看,已为你跳转到登录页面");
        router.replace("/login");
      }
    },
    children: [
      // 患者列表
      {
        path: "ReportList",
        name: "ReportList",
        component: ReportList,
      },
      // 所以报告
      {
        path: "ReportALL",
        name: "ReportALL",
        component: () => import("../components/ReportgetAll.vue"),
      },
      // 患者模糊查询
      {
        path: "Search",
        name: "Search",
        component: () => import("../components/Search.vue"),
      },
      // 查看光照报告
      {
        path: "LookRport",
        name: "LookRport",
        component: () => import("../components/LookRport.vue"),
      },
      // 新增患者
      {
        path: "AddPatient",
        name: "AddPatient",
        component: () => import("../components/AddPatient.vue"),
      },
      // 二维码
      {
        path: "QRCode",
        name: "QRCode",
        component: () => import("../components/QRCode.vue"),
      },
      // 首页
      {
        path: "Home",
        name: "Home",
        component: () => import("../components/Home.vue"),
      },
      // VR治疗方案
      {
        path: "VRPlan",
        name: "VRPlan",
        component: () => import("../components/Vrplan.vue"),
      },
      // 光照治疗方案
      {
        path: "Beam",
        name: "Beam",
        component: () => import("../components/Beam.vue"),
      },
      // CAVE运动治疗方案
      {
        path: "CAVE",
        name: "CAVE",
        component: () => import("../components/CAVE.vue"),
      },
      // 答题首页
      {
        path: "AssessmentHome",
        name: "AssessmentHome",
        component: () => import("../views/assessment/AssessmentHome.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});
// router.beforeEach((to, from, next) => {
//   // 打印用户相关资料
//   // Message.info("用户即将进入页面" + to.path);
//   Message.success("当前用户来源" + from.path);
//   next();
// });

export default router;
