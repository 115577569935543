import api from "../../http/api";
import { Message } from "element-ui";
export default {
  // 解决命名冲突
  namespaced: true,
  state: {
    data: [],
    total: 1,
    pages: 1,
    cur: 0,
    size: 1,
    details: [],
    rport: [],
    searchData: {
      cur: 1,
      size: 10,
    },
    VRRport: [],
  },
  mutations: {
    setVRReport(state, val) {
      state.VRRport = val;
      // console.log("VR训练报告", state.VRRport);
    },

    setTotal(state, val) {
      state.total = val;
      // console.log("total：", state.total);
    },
    setData(state, val) {
      state.data = val;
      // console.log("患者列表", state.data);
    },
    setRport(state, val) {
      state.rport = val;
      // console.log("报告列表", state.rport);
    },

    changeSearchData(state, val) {
      state.searchData.value = val;
    },
    changePageSize(state, val) {
      state.searchData.size = val;
    },
    changeCurrentpage(state, val) {
      state.searchData.cur = val;
    },
    // 下一页
    nextPage(state) {
      if (state.cur < state.pages) {
        state.searchData.cur++;
      }
    },
    // // 点击下拉框
    Selects(state, val) {
      state.searchData.size = val;
    },
    // 首页
    firstPage(state) {
      if ((state.searchData.cur = 1));
    },
    // 页尾
    lastPage(state) {
      if ((state.searchData.cur = state.searchData.pages));
    },
    // 上一页
    prevPage(state) {
      if (state.searchData.cur > 1) {
        state.searchData.cur--;
      }
    },
  },
  actions: {
    // 获取患者数据
    async getPatient(context) {
      const data = await api.patient.getPatient(context.state.searchData);
      context.commit("setData", data.data.data.records);
      context.commit("setTotal", data.data.data.total);
      // console.log("获取患者数据", data);
    },

    // 新增患者
    async addPatient(context, list) {
      const { data } = await api.patient.addPatient({
        list,
      });
      if (data) {
        context.dispatch("getPatient");
        // console.log("新增患者", data);
      }
    },

    // 患者详情
    async getDetails(context, ID) {
      const { data } = await api.patient.getDetails(ID);
      context.commit("setDetails", data);
    },

    // 删除患者
    async delOne(context, _id) {
      const { data } = await api.patient.delOne({ _id });
      if (data) {
        context.dispatch("getPatient");
      }
    },

    //  确定修改
    async updateCal(context, update) {
      const data = await api.patient.updateCal({ ...update });
      if (data) {
        context.dispatch("getPatient");
        // console.log(1111111, data);
      }
    },
    // 报告列表
    async lookRport(context) {
      const { data } = await api.patient.lookRport(context.state.rport);
      context.commit("setRport", data.data);
      // console.log("报告列表", data);
    },

    // 修改编辑报告
    async updateReport(context, update2) {
      const data = await api.patient.updateReport({ ...update2 });
      if (data) {
        context.dispatch("getPatient", data);
        // console.log("修改报告", data);
      }
    },
    // 删除光照报告
    async delRport(context, _id) {
      const data = await api.patient.delRport({ _id });
      if (data) {
        context.dispatch("lookRport");
      }
    },

    // 拉取VR训练报告
    async getReoprtALl(context) {
      const data = await api.patient.getReoprtALl(context.state.VRRport);
      context.commit("setVRReport", data.data.data);
      // console.log("setVRReport", data);
    },

    // 删除对应VR报告
    async delVRone(context, _id) {
      const data = await api.patient.delVRone({ _id });
      if (data) {
        context.dispatch("getReoprtALl");
      }
      // console.log(2323232, data);
    },
  },
};
