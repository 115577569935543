import axios from "../axios";
export default {
  // 获取VR方案数据
  getAllVRPlan(params) {
    return axios({
      url: "/CareType/getAll",
      method: "get",
      params,
    });
  },
  //   新增
  addVRPlan(data) {
    return axios({
      url: `/CareType/add?appid=${data.appId}&appname=${data.appName}&type=${data.type}&tname=${data.tname}&data=${data.data}`,
      method: "POST",
      data,
    });
  },
  //   删除
  delVRPlan(data) {
    return axios({
      url: `/CareType/del?_id=${data._id}`,
      method: "POST",
      data,
    });
  },
  // 确定修改
  updateCal(data) {
    return axios({
      url: "http://120.77.250.137:5000/VRPlan/okUpdate",
      method: "post",
      data,
    });
  },
};
